import styled from 'styled-components';
import ElementFeatureItem from './ElementFeatureItem';
import { clearfix } from '/style/mixin';
import theme from '/style/theme';
import useTranslation from 'next-translate/useTranslation';
import Trans from "next-translate/Trans";

const Wrapper = styled.section`
  padding: 50px 0;
  text-align: center;
  display: none;
  font-size: 0;
  background-color: #fff;
  ${clearfix}
  @media ${theme.sm} {
    display: block;
  }
  .features-wrap {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1020px;
  }
`;

const BlockFeatures = ({ data }) => {
  const { t } = useTranslation('home');
  return (
    <Wrapper>
      <h3 className="sr-only">Trazy Features</h3>
      <div className="features-wrap">
        <ElementFeatureItem
          title={t('featured.trending-now')}
          description={[
            <Trans
              i18nKey="home:featured.trending-now-description"
              components={{
                br: <br />
              }}
            >
            </Trans>
          ]}
        >
          <img
            src={require('/public/images/home/home-feature-trend.png')}
            srcSet={`
            ${require('/public/images/home/home-feature-trend.png')} 1x,
            ${require('/public/images/home/home-feature-trend@2x.png')} 2x,
            ${require('/public/images/home/home-feature-trend@3x.png')} 3x
            `}
            alt="Trend"
          />
        </ElementFeatureItem>
        <ElementFeatureItem
          title={t('featured.easy-booking')}
          description={[
            <Trans
              i18nKey="home:featured.easy-booking-description"
              components={{
                count: <strong>
                  {data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>,
                br: <br />
              }}
            >
            </Trans>
          ]}
        >
          <img
            src={require('/public/images/home/home-feature-book.png')}
            srcSet={`
            ${require('/public/images/home/home-feature-book.png')} 1x,
            ${require('/public/images/home/home-feature-book@2x.png')} 2x,
            ${require('/public/images/home/home-feature-book@3x.png')} 3x
            `}
            alt="Book"
          />
        </ElementFeatureItem>
        <ElementFeatureItem
          title={t('featured.save-up-to')}
          description={[
            <Trans
              i18nKey="home:featured.save-up-to-description"
              components={{
                br: <br />
              }}
            >
            </Trans>
          ]}
        >
          <img
            src={require('/public/images/home/home-feature-save.png')}
            srcSet={`
            ${require('/public/images/home/home-feature-save.png')} 1x,
            ${require('/public/images/home/home-feature-save@2x.png')} 2x,
            ${require('/public/images/home/home-feature-save@3x.png')} 3x
            `}
            alt="Save"
          />
        </ElementFeatureItem>
      </div>
    </Wrapper>
  );
};

export default BlockFeatures;
