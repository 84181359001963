import { useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import Link from 'next/link';
import theme from '../../style/theme';
import Close from '../../components/ui/Close';
import classNames from 'classnames';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import { setCookie } from '../../utils/cookie';
import TypeCouponCampaign from '/type/TypeCouponCampaign';
import getLinkInfo from '/utils/getLinkInfo';

const slideDown = keyframes`
  from {
    transform:translateY(0);
  }
  to {
    transform:translateY(200px);
  }
`;
const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
  &.closed {
    animation: ${slideDown} 0.3s ease-out;
    transform: translateY(200px);
  }
  .inner {
    display: inline-block;
    position: relative;
    max-width: 375px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    @media ${theme.sm} {
      max-width: 1024px;
    }
  }
  .home-banner {
    img {
      width: 100%;
      height: auto;
    }
  }
`;
interface Props {
  data: TypeCouponCampaign;
}

const HomeBanner = ({ data }: Props) => {
  const [banner, setBanner] = useState(true);

  let banner_link;
  let innerlink = true;
  if (data && data.banner_link) {
    const link_info = useMemo(() => getLinkInfo(data.banner_link), [
      data.banner_link,
    ]);
    banner_link = link_info.link;
    innerlink = link_info.is_inner_link;
  }
  return (
    <Wrapper className={classNames({ closed: !banner })}>
      <div className="inner">
        <Link href={banner_link ? banner_link : '/'} prefetch={false}>
          <a className="home-banner" target={innerlink ? '_self' : '_blank'}>
            <img
              src={data.mobile_banner}
              alt={data.campaign_title}
              className="show-mobile"
            />
            <img
              src={data.web_banner}
              alt={data.campaign_title}
              className="hide-mobile"
            />
            {/* {uistatus.screenwidth === 'mobile' ? (
              <img src={data.mobile_banner} alt={data.campaign_title} />
             ) : (
              <img src={data.web_banner} alt={data.campaign_title} />
            )} */}
          </a>
        </Link>
        <Close
          onClick={() => {
            setCookie('tr_homebanner_closed', true);
            setBanner(false);
          }}
        />
      </div>
    </Wrapper>
  );
};

export default connect(state => state, actions)(HomeBanner);
