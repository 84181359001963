export default link => {
  link = link || '';
  let is_inner_link = true;
  if (link) {
    if (link[0] !== '/') {
      const inner_link_condition =
        link.indexOf('//trazy.com') >= 0 || link.indexOf('www.trazy.com') >= 0;
      if (inner_link_condition) {
        // trazy.com, www.trazy.com
        const link_split = link.split('/');
        const target_index = link_split.findIndex(
          item => item.indexOf('trazy.com') >= 0
        );
        if (target_index >= 0) {
          link_split.splice(0, target_index + 1);
          link = '/' + link_split.join('/');
        }
      } else {
        // this could include subdomain
        is_inner_link = false;
        if (link.indexOf('http') < 0) {
          link = 'http://' + link;
        }
      }
    }
  }
  return {
    link,
    is_inner_link,
  };
};
