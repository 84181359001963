import styled from 'styled-components';
import { clearfix, transition } from '/style/mixin';
import Link from 'next/link';
import theme from '/style/theme';

const Wrapper = styled.section`
  ${clearfix}
  text-align: center;
  background-color: ${props => props.theme.light_gy1};
  border-top: 1px solid ${props => props.theme.light_gy2};
  @media ${theme.sm} {
    border-bottom: 1px solid ${props => props.theme.line_gy};
  }
  .title {
    margin: 0;
    float: left;
    display: block;
    width: 50%;
    height: 50px;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid ${props => props.theme.line_gy};
    color: ${props => props.theme.mid_gy};
    a {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      ${transition()}
      &:hover {
        color: ${theme.brand};
      }
    }
    @media ${theme.sm} {
      display: inline-block;
      height: 70px;
      line-height: 70px;
      padding: 0 20px;
      float: none;
      width: auto;
      border-bottom: 0;
    }
    &:nth-of-type(1n + 5) {
      display: none;
      @media ${theme.sm} {
        display: inline-block;
      }
    }
  }
`;

const BlockCategories = ({ data }) => {
  return data && data.length ? (
    <Wrapper>
      <h3 className="sr-only">Categories</h3>
      {data.map(v => {
        return (
          <h4 className="title" key={v.category_url}>
            <Link
              href={'/search/[slug]'}
              as={`/search/activities?categories[]=${v.category_url}`}
            >
              <a title={v.category_name}>{v.category_name}</a>
            </Link>
          </h4>
        );
      })}
    </Wrapper>
  ) : null;
};

export default BlockCategories;
