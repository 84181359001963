// @ts-nocheck
import React, { Component } from 'react';
import Layout from '/components/Layout';
import theme from '/style/theme';
import styled from 'styled-components';
import Link from 'next/link';

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  .inner-content {
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${props =>
      props.height <= 500 &&
      props.width >= 768 &&
      `
      position:relative;
      top:auto;
      left:auto;
      transform:none;
      padding-top:80px;
      padding-bottom:20px;
    `}
    @media (max-width:1080px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      border-top: 1px solid #ffffff;
      padding-top: 76px;
      padding-bottom: 135px;
    }
    @media ${theme.xs_max} {
      padding-top: 20px;
      padding-bottom: 43px;
    }
    .inner-content-left {
      height: 363px;
      width: 500px;
      display: inline-block;
      background-image: url(${require('/public/images/ui/404_error.png')});
      background-position: center center;
      background-repeat: no-repeat;
      @media (max-width: 1080px) {
        display: block;
        margin: auto;
        height: 337px;
      }
      @media ${theme.xs_max} {
        height: 202px;
        width: 100%;
        max-width: 300px;
        background-image: url(${require('/public/images/ui/404_error@2x.png')});
        background-size: contain;
      }
    }
    .inner-content-right {
      display: inline-block;
      text-align: left;
      vertical-align: top;
      padding-left: 50px;
      @media (max-width: 1110px) {
        padding-left: 30px;
      }
      @media (max-width: 1080px) {
        display: block;
        margin: 50px auto 0px auto;
        padding-left: 0px;
        max-width: 532px;
        height: auto;
      }
      @media ${theme.xs_max} {
        margin-top: 0px;
        max-width: 325px;
        padding: 0 10px;
      }
      @media (max-width: 320px) {
        margin-bottom: 40px;
      }
    }
    .error-wrapper {
      display: table;
      height: 363px;
      @media (max-width: 1080px) {
        height: auto;
        width: 100%;
      }
      .error-inner-wrapper {
        display: table-cell;
        vertical-align: middle;
        .error-message {
          font-size: 35px;
          font-weight: 600;
          color: #0097a7;
          margin-top: 0px;
          margin-bottom: 0px;
          @media ${theme.xs_max} {
            font-size: 20px;
          }
        }
        .error-desc {
          margin-bottom: 0px;
          font-size: 15px;
          color: #666666;
          line-height: 24px;
          letter-spacing: -0.2px;
          margin-top: 15px;
          @media (max-width: 320px) {
            font-size: 14px;
          }
          a {
            text-decoration: none;
            font-size: 15px;
            color: #ffffff;
            font-weight: 600;
            height: 40px;
            line-height: 40px;
            border-radius: 30px;
            background-image: linear-gradient(to left, #6cc9c9, #4dacb2);
            display: inline-block;
            padding: 0 50px;
            margin-top: 5px;
          }
        }
      }
    }
  }
`;

class LayoutNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  render() {
    const { height, width } = this.state;
    return (
      <Layout
        padding_top="0"
        padding_bottom="0"
        background_color={theme.light_gy1}
        no_footer
      >
        <Wrapper height={height} width={width}>
          <div className="inner-content">
            <div className="inner-content-left"></div>
            <div className="inner-content-right">
              <div className="error-wrapper">
                <div className="error-inner-wrapper">
                  <h2 className="error-message">Bad Connection</h2>
                  <p className="error-desc">
                    Please wait a moment and try again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default LayoutNotFound;
