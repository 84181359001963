import styled from 'styled-components';
import { transition } from '../../style/mixin';
import theme from '../../style/theme';
import Link from 'next/link';

const Wrapper = styled('a')<{
  margin_left_mobile: string;
  mint: boolean;
  gray: boolean;
}>`
    cursor: pointer;
    width: 77px;
    height: 30px;
    padding: 0;
    text-align: center;
    font-size: 15px;
    line-height: 28px;
    text-decoration: none;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: ${props => props.margin_left_mobile};
    border-radius: 20px;
    color: ${theme.mid_gy};
    border: 1px solid ${theme.line_gy};
    background-color: #fff;
    ${transition()}
    @media ${theme.lg} {
      width: 63px;
      height: 23px;
      color: ${theme.brand};
      background-color: transparent;
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      top: -37px;
      right: 0;
      bottom: auto;
      left: auto;
      border-color: ${theme.brand};
      .desktop-lowercase {
        text-transform: lowercase;
      }
      ${props =>
        props.mint &&
        `
        color: ${theme.trazy_mint};
        border-color: ${theme.trazy_mint};
        `}
      ${props =>
        props.gray &&
        `
        color: ${theme.mid_gy};
        border-color: ${theme.very_light_gy};
        `}
      &:hover {
        color: #fff;
        background-color: ${theme.brand};
        ${props =>
          props.mint &&
          `
          color: #fff;
          background-color: ${theme.trazy_mint};
        `}
        ${props =>
          props.gray &&
          `
          color: ${theme.mid_gy};
          background-color: ${theme.light_gy2};
          border-color: ${theme.very_light_gy};
          `}
      }
    }
  `;
interface Props {
  href: string;
  mint: boolean;
  gray: boolean;
  margin_left_mobile: string;
  external: boolean;
}

const SeeMore = ({ href, gray, mint, margin_left_mobile, external = false, ...rest }: Props) => {
  const wrapper = <Wrapper
      margin_left_mobile={margin_left_mobile}
      mint={mint}
      gray={gray}
      {...rest}
    >
      +<span className="sr-only">see </span>
      <span className="desktop-lowercase">M</span>ore
    </Wrapper>;

  // make prefetch false, because there are too many cases to handle
  return (href ? <Link href={href} prefetch={false} passHref={external}>{wrapper}</Link> : wrapper);
};

export default SeeMore;
