// @ts-nocheck
import { useState } from 'react';
import styled from 'styled-components';
import BlockHeading from './BlockHeading';
import Carousel from '/components/ui/Carousel';
import CityItem from '/components/list/CityItem';
import Container from '../Container';
import SeeMore from '/components/ui/SeeMore';
import Modal from '/components/ui/Modal';
import theme from '/style/theme';
import CityMenuStyle from '/components/Header/CityMenuStyle';
import { connect } from 'react-redux';
import actions from '/store/actions';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.section`
  overflow: hidden;
  background-color: ${theme.light_gy2};
  padding: 70px 0 25px;
  .slick-list {
    overflow: inherit;
  }
  .slick-dots {
    margin-right: 38px;
  }
  .swiper-slide {
    width: auto;
    height: auto;
  }
  @media ${theme.lg} {
    margin-bottom: 20px;
    padding: 80px 0 50px;
    /* disable slider */
    .slick-dots {
      display: none !important;
    }
    .slick-track,
    .slick-list {
      transform: none !important;
    }
    .slick-slider {
      width: auto !important;
    }
  }
`;
const SectionWrapper = styled.div`
  position: relative;
`;
const CarouselWrap = styled.div`
  margin: 0 -5px;
`;

const CityHeader = styled.div`
  height: 58px;
  background-color: ${props => props.theme.light_og};
  color: #fff;
  font-size: 15px;
  font-weight: ${props => props.theme.bold};
  padding: 20px;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const BlockDestinations = ({ data, title, subtitle, more }) => {
  // see more left position on mobile
  // (width of all element / 2) - width of see more
  const see_more_mobile_left = (data.length * 20 + 76) / 2 - 72 + 'px';
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('home');
  return (
    <Wrapper>
      <BlockHeading title={title} subtitle={subtitle} />
      <Container width="980">
        {open}
        <SectionWrapper>
          <CarouselWrap>
            <Carousel variableWidth noArrows dynamicItemPerPage>
              {data.map((v, i) => {
                // must wrap with div so the slider works
                return <CityItem data={v} key={`country${i}`} />;
              })}
            </Carousel>
          </CarouselWrap>
          {more ?
            <SeeMore margin_left_mobile={see_more_mobile_left} href={more} />
              :
            <SeeMore
              margin_left_mobile={see_more_mobile_left}
              onClick={() => setOpen(!open)}
            />
          }
        </SectionWrapper>
        <Modal
          open={open}
          maxWidth="720px"
          closeStyle={{ top: '10px', right: '10px' }}
          onClose={() => setOpen(false)}
        >
          <CityHeader>{t('common:country-city')}</CityHeader>
          <CityMenuStyle className="city-menu" destinations />
        </Modal>
      </Container>
    </Wrapper>
  );
};

export default connect(state => state, actions)(BlockDestinations);
