import { useRef } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import { backgroundCover, transition } from '/style/mixin';
import TypeBlogItem from '/type/TypeBlogItem';
import useLazyBg from '/utils/useLazyBg';
import Moment from 'react-moment';
import parse from '/utils/parse';

interface Props {
  data: TypeBlogItem;
}
interface ImageProps {
  cover_image?: string;
}

const PublishedDate = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: ${theme.mid_gy};
  margin-bottom: 8px;
`;
const Title = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-weight: ${theme.bold};
  color: ${theme.mid_gy};
`;
const Wrapper = styled.div<ImageProps>`
  position: relative;
  overflow: hidden;
  a {
    display: block;
    padding-bottom: 20px;
    .item-image {
      position: relative;
      ${backgroundCover}
      color: #fff;
      width: 100%;
      height: 270px;
      border-radius: 5px;
      overflow: hidden;
      opacity: 0;
      ${transition('.2s opacity ease-out')}
      &.on-screen {
        opacity: 1;
        background-image: url(${props => props.cover_image});
      }
    }
    .item-detail {
      width: calc(100% - 24px);
      margin: -20px auto 0 auto;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px ${theme.line_gy};
      padding: 20px 15px;
      background: ${theme.body_bg};
      position: relative;
      z-index: 1;
    }
    width: 335px;
    @media ${theme.sm} {
      width: 345px;
    }
    @media ${theme.md} {
      width: 270px;
    }
  }
  @media ${theme.md} {
    &:hover {
      .item-detail {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
`;
const BlogItem: React.FC<Props> = ({ data }) => {
  const target = useRef(null);
  useLazyBg(target);
  return (
    <Wrapper cover_image={data.featured_image}>
      <a target="_blank" href={data.link} title={data.title}>
        <div
          className="item-image"
          ref={target}
          data-src={data.featured_image}
        ></div>
        <div className="item-detail">
          <PublishedDate>
            <Moment format="MMM D, YYYY">{data.published_date}</Moment>
          </PublishedDate>
          <Title>{parse(data.title)}</Title>
        </div>
      </a>
    </Wrapper>
  );
};

export default BlogItem;
